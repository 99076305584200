import {Component, HostListener, OnDestroy} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import {delay, filter, take} from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ScriptsService } from "@services/scripts/scripts.service";
import { Router } from "@angular/router";
import { InternalAnalyticsService } from "@services/internal-analytics/internal-analytics.service";
import { analyticEvents } from "@services/internal-analytics/analyticEvents";
import { FeatureFlagConfig } from "@models/feature-flags/FeatureFlagConfig";
import { FeatureFlagsService } from "@services/feature-flags/feature-flags.service";
import { AuthService } from "./core/auth/auth.service";
import { TutorialsNotificationPopupComponent } from "./core/components/tutorials-notification-popup/tutorials-notification-popup.component";
import { LanguageStorageService } from "./core/util-services/language-storage.service";
import { ModalService } from "./shared/modal/modal.service";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styles: [],
})
export class AppComponent implements OnDestroy {
    title = "brame";

    private subscriptions: Subscription[] = [];

    constructor(
        private translate: TranslateService,
        private languageStorageService: LanguageStorageService,
        private auth: AuthService,
        private modals: ModalService,
        private scriptsService: ScriptsService,
        private router: Router,
        private analyticsService: InternalAnalyticsService,
        private featureFlagsService: FeatureFlagsService,
    ) {
        this.translate.setDefaultLang("en");
        this.translate.addLangs(["en", "sr"]);
        const lang = this.languageStorageService.getLanguage();
        this.translate.use(lang);
        this.scriptsService.loadServiceWidget();
        if (this.featureFlagsService.isFeatureFlagEnabled(FeatureFlagConfig.INTERNAL_ANALYTICS)) {
            this.analyticsService.triggerBrameEvent(
                analyticEvents[100].name
            ).subscribe();
        }

        this.subscriptions.push(
            this.auth.isFirstLogin$
                .pipe(
                    filter((isFirstLogin) => !!isFirstLogin),
                    delay(1500)
                )
                .subscribe(this.onFirstLogin.bind(this))
        );
    }

    @HostListener("document:visibilitychange", ["$event"])
    visibilitychange() {
        if (!document.hidden) {
            this.listenForLogout();
        }
    }

    listenForLogout() {
        this.auth.isLoggedIn.pipe(take(1)).subscribe((isLoggedIn) => {
            if (!isLoggedIn) {
                void this.router.navigate(["/login"]);
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    private onFirstLogin() {
        if (environment.isDemoEnv) {
            this.modals.open(
                TutorialsNotificationPopupComponent.dialogName,
                TutorialsNotificationPopupComponent
            );
        }
    }
}
